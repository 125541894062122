// extracted by mini-css-extract-plugin
export var button = "Button-module--button--c5340";
export var disabled = "Button-module--disabled--8b108";
export var flex = "Button-module--flex--b8aa7";
export var flexCenter = "Button-module--flex_center--8f430";
export var gray = "Button-module--gray--5d8e5";
export var large = "Button-module--large--e2920";
export var medium = "Button-module--medium--fe122";
export var normalizeButton = "Button-module--normalize-button--55b5f";
export var plain = "Button-module--plain--31978";
export var play = "Button-module--play--535e5";
export var red = "Button-module--red--318a9";
export var rotating = "Button-module--rotating--d81e2";
export var small = "Button-module--small--dff6f";
export var white = "Button-module--white--19d9e";
export var xsmall = "Button-module--xsmall--9117d";